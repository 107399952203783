import Styled from 'styled-components';

import { baseMixin } from '../mixins';

const Wrapper = Styled.div`
  ${baseMixin};

  .main-content {
    max-width: 80%;
    margin: 0 auto;

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    li {
      margin-bottom: 30px;
    }

    h3 {
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
`;

export { Wrapper };
