import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../layouts/mainLayout';

import HeroImage from '../components/heroImage';

import { Wrapper } from '../styles/pages/move-out-checklist';

export const query = graphql`
  query moveOutChecklistPageQuery {
    hero: file(relativePath: { regex: "/move-out-checklist.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const hero = data => {
  const image = <Img fluid={data.hero.childImageSharp.fluid} />;
  return <HeroImage backdrop={false} image={image} />;
};

const MoveOutChecklistPage = ({ data }) => {
  return (
    <Layout hero={hero(data)}>
      <Helmet>
        <meta
          name="description"
          content="Take advantage of this student move-out checklist for apartment living to get more of your security deposit back."
        />
        <title>Student Move-Out Checklist | Sci-Tech Carpet Cleaning</title>
      </Helmet>
      <Wrapper>
        <div className="main-content">
          <p>
            If you're about to move out of your apartment, I'm sure you are
            quite stressed. Moving is an extremely stressful process. It's even
            worse when you are concerned about getting your security deposit
            back.
          </p>
          <p>
            Getting your full deposit back is difficult since your landlord will
            likely need to use some of it to finish your move out process. You
            can increase your odds by doing most of the process yourself,
            though. This includes cleaning your apartment, repairing damages,
            and cleaning your carpets.
          </p>
          <p>
            Follow this checklist to help guide you towards a larger security
            deposit refund, and share it on Facebook with the link at the bottom
            if it helped you!
          </p>
          <h2>General Areas</h2>
          <ul>
            <li>
              <h3>Carpets cleaned</h3>
              <p>
                The carpets need to be freshly cleaned when you move out. If you
                don't take care of this yourself, your landlord will do it for
                you. They will then take the cost (typically plus a convenience
                fee) out of your deposit. By scheduling it yourself, you can
                save the convenience fee.
              </p>
              <p>
                I also strongly recommend using us for your carpet cleaning
                needs. We already handle the carpet cleaning for nearly all of
                the student-housing market in Blacksburg and Radford. Because of
                this, you can be sure that your landlord won't choose to have
                the carpet cleaned a second time (taken from your security
                deposit) due to a lack of trust in the carpet cleaning process.
              </p>
            </li>
            <li>
              <h3>Holes in the wall repaired</h3>
              <p>
                If you have accidentally knocked holes into the walls, they will
                need to be repaired. This also includes pin holes from posters.
              </p>
            </li>
            <li>
              <h3>
                If the apartment is furnished, the furniture might need to be
                cleaned as well
              </h3>
              <p>
                Consult your landlord about this. Not all landlords will require
                this step.
              </p>
            </li>
            <li>
              <h3>Windows cleaned on both sides</h3>
              <p>
                Thoroughly clean the windows on the inside and the outside.
                There should be no smudges or dirty spots on the windows.
              </p>
            </li>
            <li>
              <h3>Ceiling fans cleared of dust</h3>
              <p>
                Dust accumulates on the top of ceiling fan blades. The ceiling
                fan blades will need to be cleared of any dust.
              </p>
            </li>
            <li>
              <h3>Walls and corners cleared of cobwebs and dust</h3>
              <p>
                We all know how cobwebs build up in the corners. Use a feather
                duster to remove them.
              </p>
            </li>
            <li>
              <h3>Thoroughly dust apartment</h3>
              <p>
                Try to make your apartment surfaces pass the "white glove test."
              </p>
            </li>
            <li>
              <h3>Scuffs on walls fixed and/or painted</h3>
              <p>
                Black scuff marks will be immediately noticed when the
                inspection is performed on your apartment. Clean them off the
                walls (or touch up paint over them) to help the walls look
                great.
              </p>
            </li>
            <li>
              <h3>Light fixtures cleaned and dead bugs remvoed from them</h3>
              <p>
                This will be one of the first things management notices when
                they walk your apartment. If your light fixtures are clean and
                looking good, it will go a long way towards helping you get your
                security deposit back.
              </p>
            </li>
            <li>
              <h3>
                All furniture, trash, and other belongings removed from the
                apartment
              </h3>
              <p>The apartment needs to be completely empty when you leave.</p>
            </li>
            <li>
              <h3>Carpet damages repaired</h3>
              <p>
                Consult with your landlord first. If there are tears, bleach
                stains, or other damages to your carpet, your landlord might
                expect you to pay for the repairs.
              </p>
            </li>
            <li>
              <h3>New stains removed from carpets</h3>
              <p>
                You won't be held responsible for stains on the carpet that were
                listed on your move-in report. New stains, however, will be your
                responsibility. Try to have these removed at the time of your
                carpet cleaning.
              </p>
            </li>
          </ul>
          <h2>Kitchen</h2>
          <ul>
            <li>
              <h3>Refrigerator cleaned</h3>
              <p>
                Remove all food from the refrigerator. It should be completely
                empty. Then thoroughly clean it so that it looks and smells
                fresh.
              </p>
            </li>
            <li>
              <h3>Clean counter tops</h3>
              <p>
                Clean and remove any stains from counter tops. Coffee stains
                might be particularly difficult to remove.
              </p>
            </li>
            <li>
              <h3>Clean underneath, behind, and on top of the refrigerator</h3>
              <p>
                This is an important point. I have heard stories of deposits
                being withheld because the tenant failed to do this. Make sure
                to move the refrigerator and clean all around it.
              </p>
            </li>
            <li>
              <h3>Oven and stove top cleaned</h3>
              <p>
                This might be the hardest step. Make sure to clean out the drip
                pans (or replace them if necessary). Fully clean any food
                droppings from the oven as well.
              </p>
            </li>
            <li>
              <h3>Tile and hard floors swept and mopped</h3>
              <p>
                Most of the time, this is enough to make the hard floors look
                good. If this isn't cutting it, however, you might need to have
                them professionally cleaned.
              </p>
            </li>
          </ul>
          <h2>Bathrooms</h2>
          <ul>
            <li>
              <h3>Mirrors and metallic surfaces cleaned and shiny</h3>
              <p>
                Use Windex or a similar product to clean the mirrors and
                metallic surfaces such as the faucet. They should all look
                perfectly clean and shiny.
              </p>
            </li>
            <li>
              <h3>Toilets and sinks cleaned</h3>
              <p>
                It's a dirty job, but it has to be done. There should be no
                stains in the toilet bowl as well.
              </p>
            </li>
            <li>
              <h3>Soap scum removed from shower and bath fixtures</h3>
              <p>
                This might require some scrubbing. Use strong and appropriate
                cleaners to make this step easier.
              </p>
            </li>
          </ul>
          <p>
            Following this checklist will go a long way towards helping you get
            your deposit back. The best rule of thumb to go by is to remember
            that your landlord will want the apartment to be in the same
            condition it was when you moved in.
          </p>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default MoveOutChecklistPage;
